<template>
    <v-container fluid class="container--fluid fill-height text-left pa-0 backcolor" style="width: 100%">
        <v-row>
            <v-img src="@/assets/images/header-bg.jpg"
                   class="align-end"
                   transition="slide-x-transition"
                   width="100%" height="280">
                <v-row align="end" justify="start">
                    <v-col cols="12">
                        <div class="fill-height container--fluid px-2 px-md-16 align-center pb-10">
                            <v-app-bar tile elevation="0" color="transparent" height="100">
                                <v-avatar
                                    color="grey lighten-2"
                                    size="100">
                                    <v-img
                                        :src="profile.user.pictures.length > 0 ? profile.user.pictures[0].url :
                                        require('@/assets/images/user-128-128.png')"
                                        height="96"
                                        v-if="getStatusLogin && profile.user">
                                    </v-img>
                                    <v-icon x-large v-else>mdi-account</v-icon>
                                </v-avatar>
                                <span class="ml-5 font-weight-thin text-h6 text-md-h3 white--text" v-show="getStatusLogin" v-if="profile.user">
                                    <span class="text-subtitle-1 ml-1 yellow--text">Inversionista:</span>
                                    <v-divider dark style="border-width: 1px;"/>
                                    {{ profile.user.name }} {{ profile.user.last_name }}
                                </span>
                                <v-spacer></v-spacer>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn rounded color="yellow"
                                               depressed plain icon
                                               v-on="on"
                                               v-bind="attrs"
                                               v-on:click="doLogout">
                                            <v-icon size="48" >mdi-exit-to-app</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Cerrar sessión</span>
                                </v-tooltip>
                            </v-app-bar>
                            <v-divider dark class="mt-2"/>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
        <v-row>
            <v-container fluid class="fill-height container--fluid mt-10">
                <v-row justify="center">
                    <v-col cols="12" lg="6" class="text-center justify-center">
                        <p class="font-weight-light display-2 mb-3 text-h4 text-md-h3 grey--text text--darken-3">
                            Portafolio de Inversión
                        </p>
                        <v-row justify="center">
                            <div class="boxdivider"></div>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row align="start" justify="center" class="text-left mt-5">
                    <v-col cols="10">
                        <v-container fluid class="fill-height container--fluid">
                            <v-row>
                                <v-col v-for="(item, index) in projects"
                                       :key="index"
                                       cols="12" md="6"
                                       class="px-0 px-md-5">
                                    <v-hover v-slot:default="{ hover }">
                                        <v-card :elevation="hover ? 10 : 4"
                                                class="card mx-0"
                                                @click="showProject(item.id)">
                                            <v-img :src="item.pictures.length>0 ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                                   height="350" width="auto"
                                                   gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                                   aspect-ratio="1"
                                                   class="align-end mt-0 ml-0">
                                                <v-container fluid class="fill-height container--fluid ma-auto mb-2">
                                                    <v-row align="end" justify="start">
                                                        <v-col cols="12">
                                                            <table cellpadding="0" cellspacing="0" width="100%">
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <span class="white--text">
                                                                            {{ item.name }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="vertical-align:top" width="auto">
                                                                        <v-icon color="orange" small>mdi-map-marker</v-icon>
                                                                    </td>
                                                                    <td>
                                                                        <span class="white--text text-caption">
                                                                            {{ item.location }}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <v-card-actions class="px-0 py-0 justify-end">
                                                                            <v-btn tile depressed dark color="orange" x-small
                                                                                   @click="showProject(item.id)">Ver mas</v-btn>
                                                                        </v-card-actions>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-img>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
                <v-row justify="center" class="ma-10" v-if="projects.length === 0">
                    <div class="text-subtitle-1 text-md-h4">
                        No hay Proyectos en el catálogos...
                    </div>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="6" class="text-center justify-center">
                        <p class="font-weight-light display-2 mb-3 text-h4 text-md-h3 grey--text text--darken-3">
                            Prospectos
                        </p>
                       <!-- <v-row justify="center">
                            <div class="boxdivider"></div>
                        </v-row>-->
                    </v-col>
                </v-row>
                <v-row align="start" justify="center">
                    <v-col cols="12">
                        <div class="fill-height pa-1 pa-md-5">
                            <v-slide-group
                                v-model="model"
                                active-class="blue lighten-4"
                                center-active
                                class="pa-0"
                                next-icon="mdi-arrow-right-drop-circle-outline"
                                prev-icon="mdi-arrow-left-drop-circle-outline"
                                show-arrows>
                                <v-slide-item
                                    v-for="item in prospects"
                                    :key="item.id"
                                    v-slot="{ active, toggle }">
                                    <v-hover v-slot:default="{ hover }">
                                        <v-card :elevation="hover ? 10 : 4"
                                                class="card mx-2"
                                                @click="toggle; openDialog(item.id);">
                                            <v-img :src="item.pictures.length>0 ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                                   :height="$vuetify.breakpoint.smAndDown ? 240 : 350"
                                                   :width="$vuetify.breakpoint.smAndDown ? 240 : 450"
                                                   gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                                   aspect-ratio="1"
                                                   class="align-end mt-0 ml-0">
                                                <v-container fluid class="fill-height container--fluid ma-auto mb-2 align-end">
                                                    <v-row align="end" justify="start">
                                                        <v-col cols="12" md="7">
                                                            <table cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <span class="white--text">
                                                                            {{ item.name }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="vertical-align: top">
                                                                        <v-icon color="orange" small>mdi-map-marker</v-icon>
                                                                    </td>
                                                                    <td>
                                                                        <span class="white--text text-caption">
                                                                            {{ item.location }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </v-col>
                                                        <v-col cols="12" md="5">
                                                            <v-card-actions class="px-0 py-0 justify-start justify-md-end">
                                                                <v-btn tile depressed dark color="orange" :x-small="$vuetify.breakpoint.mdAndDown"
                                                                       @click="openDialog(item.id)">Me Interesa</v-btn>
                                                            </v-card-actions>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-img>
                                        </v-card>
                                    </v-hover>
                                </v-slide-item>
                            </v-slide-group>
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center" class="ma-10" v-if="prospects.length === 0">
                    <div class="text-subtitle-1 text-md-h4">
                        No hay Prospectos en el catálogos...
                    </div>
                </v-row>
            </v-container>
        </v-row>
        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <div class="row--dense mx-1 mt-2">
                        <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="Correo electrónico"
                            required>
                        </v-text-field>
                    </div>
                    <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                               text
                               @click="closeDialog">
                            Cancelar
                        </v-btn>
                        <v-btn :disabled="!valid"
                               color="primary"
                               text
                               @click="sendEmail()">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-snackbar
            :timeout="-1"
            v-model="snackDialog"
            color="orange"
            elevation="24"
            vertical
            centered>
            <span class="text-subtitle-1 black--text"> {{ snackInfo }} </span>
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs"
                       light
                       text
                       @click="sendEmail()">
                    Aceptar
                </v-btn>
                <v-btn  v-bind="attrs"
                        light
                        text
                        @click="snackDialog = false">
                    Cancelar
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar
            v-model="snackBar"
            :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn  v-bind="attrs"
                        icon
                        @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import loginService from "@/providers/LoginService";
    import investorService from "@/providers/InvestorService";
    import projectsService from '@/providers/ProjectsService';
    import preferencesService from "@/providers/PreferencesService";
    import moment from 'moment';

    export default {
        name: "InvestorProfileComponent",
        data: () => ({
            model: null,
            investor: null,
            profile: null,
            project_id: 0,
            investor_id: 0,
            snackBar: false,
            snackDialog: false,
            snackText: '',
            snackInfo: '',
            valid: true,
            dialog: false,
            email: null,
            projects: [],
            prospects: [],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
        }),
        methods: {
            getStatusLogin() {
                return this.profile.isLogin;
            },
            doLogout() {
                loginService.logoutUser(false);
            },
            showProject(id) {
                this.$router.push({
                    name: "ProjectProfile",
                    params: {
                        id: id,
                        view: 'investor',
                        user: 'investor'
                    }
                });
            },
            getProspects() {
                // Lista de Proyectos
                projectsService.getViewList().then(record => {
                    this.prospects = record.value;
                });
            },
            getProfile(id) {
                investorService.getRecord(id).then(
                    response => {
                        if (response.success) {
                            this.investor = response.value;
                            // console.log('Inversionista Profile: ', this.investor);
                            this.projects = this.investor.projects;
                            // console.log('Inversionista Projects: ', this.projects);
                        } else {
                            this.investor = null;
                        }
                    }
                );
            },
            openDialog(id) {
                this.project_id = id;
                this.snackInfo = 'Desea etiquetar este proyecto?';
                this.snackDialog = true;
            },
            closeDialog() {
                this.project_id = 0;
                this.snackInfo = '';
                this.snackDialog = false;
            },
            sendEmail() {
                // console.log('Enviando correo por el Id: ', this.project_id);
                this.snackDialog = false;
                this.sendPreference(this.project_id);
            },
            sendPreference(project_id) {
                let current_date = moment(new Date()).format("YYYY/MM/DD")
                // console.log('Fecha curr_date: ', current_date);
                preferencesService.addRecord('like', current_date, project_id, this.investor.id).then(result => {
                    if (result.success) {
                        this.snackText = 'Se ha enviado los datos de esta vista a su buzón de correo!';
                        // Enviar los datos por correo electronico.
                        projectsService.sendEmail(project_id, this.investor.user.email).then(data => {
                            if (data.success) {
                                console.log('Correo enviado: ', data);
                            } else {
                                console.log('Error enviando correo: ', data);
                                alert('Error enviando correo: '+ data);
                            }
                        });
                    } else {
                        this.snackText = 'Un error impidió etiquetar la vista!';
                    }
                    this.snackBar = true;
                });
            },
        },
        beforeMount() {
            this.getProspects();
            this.profile = Object.assign({}, JSON.parse(sessionStorage.getItem('investor')));
            // console.log('Profile: ', this.profile);
            this.getProfile(this.$route.params.id);
            // this.user = this.profile.user;
            // this.$store.getters.getUser;
            // console.info('Usuario Profile: ', this.user);
            // let profile = null;
            // profile = Object.assign({}, JSON.parse(sessionStorage.getItem('investor')));
            // Object.assign(this.investor, JSON.parse(sessionStorage.getItem('investor')));
            // console.log('Profile: ', profile);
            // this.getProfile(profile.user.investors[0].id);
        }
    }
</script>

<style scoped>
    .backcolor {
        background-color: #ffffff;
        margin-top: 0px;
    }
    .boxdivider {
        background-color: transparent;
        width: 450px;
        height: 10px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        /*border-bottom-color: blueviolet;*/
        border-bottom-color: #1E9FFF;
        border-bottom-style: solid;
        display: block;
    }
</style>