<template>
    <investor-profile-component/>
</template>

<script>
    import InvestorProfileComponent from "@/components/investors/InvestorProfileComponent";
    
    export default {
        name: "InvestorProfile",
        title: "Perfil Inversionista | Private",
        components: {InvestorProfileComponent}
    }
</script>

<style scoped>

</style>